import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  Box, Button, Icon, Grid, GridItem, Text, useColorModeValue,
  Flex, Input, VStack, IconButton, Link, Breadcrumb, BreadcrumbItem, BreadcrumbLink
} from '@chakra-ui/react';
import { AddIcon, ArrowBackIcon, ChevronRightIcon, LockIcon } from '@chakra-ui/icons';
import { fetchLearnerProfileData, fetchSearchSuggestions, fetchSearchSuggestionsSuccess, addUserCourse, showAddCourseForm, hideAddCourseForm, checkLockedStatus } from '../../redux/actions/learnerProfileActions';
import { convertDataToExpectedFormat, convertMasteryLevelToText, calculateAverageMasteryLevel } from './utils'; 
import { createCheckoutSessionApi } from '../../utils/api';
import { startLearningSession, startCalibrationSession } from '../../redux/actions/chatActions';
import { closeAllComponents } from '../../redux/actions/headerActions';

const TableComponent = ({ onAddCourseClick }) => {
  const dispatch = useDispatch();
  const showForm = useSelector(state => state.learnerProfile.showAddCourseForm);
  const borderColor = useColorModeValue('gray.100', 'gray.900');
  const headerColor = useColorModeValue('gray.100', 'gray.800');
  const textColor = useColorModeValue('black', 'whiteAlpha.900');
  const secondaryTextColor = useColorModeValue('teal.600', 'teal.200');
  const Courses = useSelector(state => state.learnerProfile.data);
  const [currentLevelData, setCurrentLevelData] = useState(Object.keys(Courses ?? {}).length ? [Courses] : []);

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const searchSuggestions = useSelector(state => state.learnerProfile.searchSuggestions);
  const [breadcrumbPath, setBreadcrumbPath] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  // Ensure currentLevelData is updated correctly when Courses changes
  useEffect(() => {
    if (Courses && Courses.name) {
      console.log('Initial Courses data:', Courses);
      setCurrentLevelData([Courses]); // Assuming Courses is an object with the expected structure
    }
  }, [Courses]);

  const handleCourseSelect = (courseId) => {
    setSelectedCourseId(courseId);
  };

  const setCurrentLevelDataWithBreadcrumb = (breadcrumbPath, courses) => {
    let currentData = courses; // Start with the root level of the courses data

    console.log('Navigating with breadcrumb path:', breadcrumbPath);

    // Navigate down the structure based on the breadcrumb path
    breadcrumbPath.forEach(crumb => {
      if (typeof currentData === 'object' && currentData !== null) {
        let found = false;
        Object.entries(currentData).forEach(([key, value]) => {
          if (typeof value === 'object' && value.name === crumb && !found) {
            currentData = value; // Navigate to this child
            found = true;
          } else if (typeof value === 'object' && !Array.isArray(value) && !found) {
            Object.values(value).forEach(innerValue => {
              if (innerValue.name === crumb) {
                currentData = innerValue; // Navigate to this nested child
                found = true;
              }
            });
          }
        });
      }
      console.log('Current data after navigating to:', crumb, currentData);
    });

    // Compile next level data based on currentData
    let nextLevelData = [];
    let isLeafNode = false; // Flag to identify if the currentData is a leaf node

    if (typeof currentData === 'object' && currentData !== null) {
      if (currentData.hasOwnProperty('mastery_level')) {
        isLeafNode = true;
        console.log('Leaf node identified: mastery_level found');
      } else {
        console.log('Not a leaf node, available fields:', Object.keys(currentData));
      }
      // If not a leaf node, compile navigable children
      if (!isLeafNode) {
        Object.keys(currentData).forEach(key => {
          const value = currentData[key];
          if (key !== 'name' && typeof value === 'object' && value.name) {
            nextLevelData.push(value); // Navigable child
          } else if (typeof value === 'object' && !Array.isArray(value)) {
            // Nested objects could be potential navigable children
            Object.values(value).forEach(subValue => {
              if (subValue.name) {
                nextLevelData.push(subValue); // Nested navigable child
              }
            });
          }
        });
      }
    }

    console.log('Next level data:', nextLevelData);
    console.log('Is leaf node:', isLeafNode);

    // Update state based on whether we are at a leaf node or not
    if (isLeafNode) {
      // Do not update currentLevelData since we're at a leaf node
    } else {
      setCurrentLevelData(nextLevelData); // Update with next level data
    }
  };

  const handleItemClick = (item) => {
    const itemName = item.name;
    if (item.id) {
      setSelectedCourseId(item.id);
      console.log("Course selected with ID:", item.id);
    }
    if (item.hasOwnProperty('mastery_level')) {
      console.log('Leaf node clicked:', item);
      // Leaf node clicked, no further action such as adding to breadcrumbPath or updating currentLevelData
    } else {
      setBreadcrumbPath(prevPath => {
        const newPath = [...prevPath, itemName];
        console.log('Updated breadcrumb path:', newPath);
        return newPath;
      });

      // Update current level data after state update
      setTimeout(() => {
        setCurrentLevelDataWithBreadcrumb([...breadcrumbPath, itemName], Courses);
      }, 0);
    }
  };

  const handleBreadcrumbClick = (crumb, index) => {
    if (index === 0) {
      // Reset to initial state for root breadcrumb
      setBreadcrumbPath([]);
      setSelectedCourseId(null);
      setCurrentLevelData([Courses]); // Assuming 'courses' holds the initial root level data
      console.log("Reset to initial state");
    } else {
      const newPath = breadcrumbPath.slice(0, index + 1);
      setBreadcrumbPath(newPath);
      console.log('Breadcrumb path after click:', newPath);
      setCurrentLevelDataWithBreadcrumb(newPath, Courses);
    };
  }

  const handleShowForm = () => {
    dispatch(showAddCourseForm());
  };

  const handleHideForm = () => {
    dispatch(hideAddCourseForm());
    setSearchQuery(''); // Reset search query
    dispatch(fetchSearchSuggestionsSuccess([])); // Clear search suggestions
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    if (selectedCourse) {
      dispatch(addUserCourse(selectedCourse));
      dispatch(hideAddCourseForm()); // Hide the form after submission
    }
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      dispatch(fetchSearchSuggestions(query));
    }, 300),
    [dispatch]
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query.length > 0) {
      debouncedSearch(query);
    } else {
      dispatch(fetchSearchSuggestionsSuccess([])); // Clear suggestions if query is empty
    }
  };

  const selectSuggestion = (suggestion) => {
    setSelectedCourse(suggestion);
    setSearchQuery(suggestion.name);
    dispatch(fetchSearchSuggestionsSuccess([])); // Clear suggestions after selection
  };

  const handleCalibrateClick = (courseId) => {
    console.log('Calibrate button clicked for course ID:', courseId);
    dispatch(startCalibrationSession(courseId));
    dispatch(closeAllComponents());
  };

  const handleUpgradeNow = async (courseId) => {
    console.log('Upgrade button clicked for course ID:', courseId);
    try {
      const { url } = await createCheckoutSessionApi(courseId);

      if (url) {
        window.location.href = url;
        dispatch(closeAllComponents());
      } else {
        console.error("Failed to get checkout URL");
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  const handleStudyClick = (objectiveId) => {
  const courseId = Courses.id; 
  console.log('currentLevelData', currentLevelData)
  console.log('objectiveId', objectiveId)

  if(courseId && objectiveId) {
    dispatch(checkLockedStatus(courseId, objectiveId));
    dispatch(closeAllComponents())
  } else {
    console.error('Missing courseId or objectiveId');
  }
};

  return (
    <Box
      className="table-container"
      p={4}
      bg={borderColor}
      borderRadius="md"
      width="50vw"
      height="auto"
      maxHeight="70vh"
      overflowY="auto"
      display="flex"
      flexDirection="column"
    >
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        {breadcrumbPath.length > 0 ? (
          <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
            {breadcrumbPath.map((crumb, index) => (
              <BreadcrumbItem key={index} isCurrentPage={index === breadcrumbPath.length - 1}>
                <BreadcrumbLink
                  as="span"
                  cursor="pointer"
                  onClick={() => handleBreadcrumbClick(crumb, index)}
                  isTruncated
                  maxWidth="120px"
                >
                  {crumb}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        ) : (
          <Text fontSize="lg" fontWeight="bold" color={textColor}>Learner Profile</Text>
        )}
        {!showForm && (
          <Button colorScheme="teal" variant="ghost" onClick={handleShowForm}>
            <Icon as={AddIcon} w={4} h={4} color={textColor} />
          </Button>
        )}
      </Flex>
      {showForm ? (
        <>
          <IconButton
            icon={<ArrowBackIcon w={6} h={6}/>}
            colorScheme="white"
            variant="ghost"
            aria-label="Back"
            position="absolute"
            top="0"
            left="1"
            size="md"
            onClick={handleHideForm}
          />
          <VStack spacing={4} position="relative">
            <Input
              placeholder="Type to search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {searchSuggestions.length > 0 && (
              <Box 
                position="absolute"
                mt={10} 
                width="100%" 
                zIndex="10" 
                bg={borderColor}>
                {searchSuggestions.map((suggestion, index) => (
                  <Box
                    key={suggestion.id}
                    onClick={() => selectSuggestion(suggestion)}
                    p={2} 
                    cursor="pointer"
                    _hover={{ bg: "gray.800" }}
                  >
                    {suggestion.name}
                  </Box>
                ))}
              </Box>
            )}
            <Button colorScheme="teal" variant="outline" onClick={handleSubmit}>Confirm</Button>
          </VStack>
        </>
      ) : (
        <>
          <Grid templateColumns="repeat(3, 1fr)" gap={4} bg={headerColor} p={2} borderRadius="md">
            <GridItem colSpan={1}><Text fontWeight="bold" color={textColor}>Course Name</Text></GridItem>
            <GridItem colSpan={1}><Text fontWeight="bold" color={textColor}>Progress</Text></GridItem>
            <GridItem colSpan={1}><Text fontWeight="bold" color={textColor}>Actions</Text></GridItem>
          </Grid>
          {currentLevelData.length === 0 ? (
            <Flex justifyContent="center" alignItems="center" py={5} color={textColor}>
              <Text>No courses added yet.</Text>
              <Link color={secondaryTextColor} onClick={handleShowForm} fontWeight="bold"> Add a course</Link>
            </Flex>
          ) : (
            currentLevelData.map((item, index) => (
              <Box key={index} 
                   p={2} 
                   mb={2} 
                   cursor="pointer" 
                   borderWidth="1px" 
                   borderRadius="md"
                   _hover={{ bg: "gray.700" }}
                   onClick={() => handleItemClick(item)}>
                <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                  <GridItem colSpan={1}><Text color={textColor}>{item.name}</Text></GridItem>
                  <GridItem colSpan={1}>
                    <Text color={textColor}>
                      {item.hasOwnProperty('mastery_level') ? 
                        convertMasteryLevelToText(item.mastery_level) : 
                        `${(calculateAverageMasteryLevel(item) * 100).toFixed(0)}%`}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={1}>
                    {breadcrumbPath.length === 0 && item.calibration && !item.calibrated ? (
                      <Button 
                        size="sm" 
                        colorScheme="teal" 
                        variant="outline"
                        _hover={{
                          borderWidth: "2px",
                          padding: "calc(0.75rem - 1px)",
                          fontWeight: "bold"
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCalibrateClick(item.id);
                        }}>Calibrate</Button>
                    ) : item.hasOwnProperty('mastery_level') ? (
                      item.locked ? (
                        <Button 
                          size="sm" 
                          colorScheme="teal"
                          variant="outline"
                          _hover={{
                            borderWidth: "2px",
                            padding: "calc(0.75rem - 1px)",
                            fontWeight: "bold"
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUpgradeNow(item.id);
                          }}>
                          <LockIcon mr="2" />Upgrade
                        </Button>
                      ) : (
                        <Button 
                          size="sm" 
                          colorScheme="teal"
                          variant="outline"
                          _hover={{
                            borderWidth: "2px",
                            padding: "calc(0.75rem - 1px)",
                            fontWeight: "bold"
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleStudyClick(item.id);
                          }}>
                          Study
                        </Button>
                      )
                    ) : (
                      <Text color={textColor}>-</Text> // Display '-' for non-leaf nodes without actions
                    )}
                    {console.log('Item details:', item)}
                    {console.log('Item.locked:', item.locked)}
                    {console.log('Item.mastery_level:', item.mastery_level)}
                  </GridItem>
                </Grid>
              </Box>
            ))
          )}
        </>
      )}
    </Box>
  );
}

export default TableComponent;
