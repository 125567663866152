import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  Flex,
  Box,
  Image,
  IconButton,
  Text,
  useColorModeValue,
  VStack,
  Portal,
  Link,
  Icon,
  Button,
  Spacer
} from '@chakra-ui/react';
import { CalendarIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa';
import logo from '../../assets/images/Logo.png';
import LearnerProfile from '../LearnerProfile/LearnerProfile';
import LearningSchedule from '../LearningSchedule/LearningSchedule';
import ActivityTimer from '../Timers/ActivityTimer'; // Adjust the path as necessary
import SessionTimer from '../Timers/SessionTimer';
import { toggleNotepad } from '../../redux/actions/notepadActions';
import { toggleLearnerProfile, toggleLearningSchedule, toggleAccount } from '../../redux/actions/headerActions';


const UserInitialIcon = () => (
  <Box borderRadius="full" border="2px" borderColor="teal" display="inline-flex" alignItems="center" justifyContent="center" w={8} h={8}>
    B
  </Box>
);

const Header = ({ onLogout,isNotepadVisible, style }) => {
  const dispatch = useDispatch();
  const bgColor = useColorModeValue('gray.100', 'gray.900');
  const iconColor = useColorModeValue('teal.500', 'teal.200');

  const isLearnerProfileOpen = useSelector(state => state.header.isLearnerProfileOpen);
  const isLearningScheduleOpen = useSelector(state => state.header.isLearningScheduleOpen);
  const isAccountOpen = useSelector(state => state.header.isAccountOpen);

  const handleToggleNotepad = () => {
    dispatch(toggleNotepad());
  };

  const handleToggleLearnerProfile = () => {
    dispatch(toggleLearnerProfile()); // Corrected to use a distinct name
  };

  const handleToggleLearningSchedule = () => {
    dispatch(toggleLearningSchedule());
  };

  const handleToggleAccount = () => {
    dispatch(toggleAccount());
  };


  return (
    <Flex
      as="nav"
      style={style}
      align="center"
      justify="space-between"
      wrap="wrap"
      height="60px"
      bg={bgColor}
      boxShadow="sm"
      width="full"
      position="sticky"
      top="0"
      zIndex="sticky"
    >
      {/* Left-aligned items */}
      <Flex align="center" mr={5}>
        <RouterLink to="/" >
          <Image
            src={logo}
            alt="Company logo"
            htmlWidth="150px"
            objectFit="contain"
            pl="10px"
          />
        </RouterLink>
      </Flex>

      {/* Center-aligned Activity Timer */}
      <Spacer />
      <ActivityTimer />
      <Spacer />

      {/* Right-aligned items */}
      <Box display="flex" alignItems="center">
        <SessionTimer />
        <IconButton
          icon={<CalendarIcon />}
          aria-label="Learning Schedule"
          variant="ghost"
          color={iconColor}
          fontSize="20px"
          marginRight="1"
          onClick={handleToggleLearningSchedule}
        />

        <IconButton
          icon={<Icon as={FaUserCircle} />}
          aria-label="Learning Profile"
          variant="ghost"
          color={iconColor}
          fontSize="20px"
          marginRight="1"
          onClick={handleToggleLearnerProfile}
        />

        <Box
          as="button"
          onClick={handleToggleAccount}
          marginRight="1"
        >
          <UserInitialIcon />
        </Box>

        {!isNotepadVisible && (
          <IconButton
            icon={<ChevronLeftIcon />} // Replace with a suitable icon for the notepad
            aria-label="Toggle Notepad"
            variant="ghost"
            color={iconColor}
            fontSize="35px"
            onClick={handleToggleNotepad}
            opacity="0.5"
          />
        )}
      </Box>

      {isLearningScheduleOpen && (
        <VStack
          align="stretch"
          position="absolute"
          right="1.5rem"
          top="100%" // Start at the bottom of the header
          p={4}
          bg={bgColor}
          boxShadow="md"
          borderRadius="md"
          zIndex="popover"
          minW="200px"
        >
          <LearningSchedule />
        </VStack>
      )}

      {isLearnerProfileOpen && (
        <VStack
          align="stretch"
          position="absolute"
          right="1.5rem"
          top="100%" // Start at the bottom of the header
          p={4}
          bg={bgColor}
          boxShadow="md"
          borderRadius="md"
          zIndex="popover"
          minW="200px"
        >
          <LearnerProfile />
        </VStack>
      )}

      {isAccountOpen && (
        <VStack
          align="stretch"
          position="absolute"
          right="1.5rem"
          top="100%" // Start at the bottom of the header
          p={4}
          bg={bgColor}
          boxShadow="md"
          borderRadius="md"
          zIndex="popover"
          minW="200px"
        >
          <Text fontSize="lg" fontWeight="bold">
            My Account
          </Text>
          <Button colorScheme="teal" variant="solid" size="sm">
            Add Course
          </Button>
          <Text fontSize="sm">Learning Summary: Placeholder</Text>
          <Link color={iconColor} onClick={onLogout} display="flex" alignItems="center" fontSize="sm">
            <Icon as={FaSignOutAlt} marginRight="2" />
            Log Out
          </Link>
        </VStack>
      )}
    </Flex>
  );

};

export default Header;
