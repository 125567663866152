// authSagas.js
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  REGISTER_REQUEST,
  registerSuccess,
  registerFailure,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  VERIFY_TOKEN_REQUEST,
  loginSuccess,
  loginFailure,
  REFRESH_TOKEN_REQUEST,
  logoutRequest,
  VERIFY_TOKEN_FAILURE,
  logoutSuccess,
  logoutFailure
} from '../actions/authActions';
import { loginUser, verifyTokenApi, refreshTokenApi, logoutUser, registerApi } from '../../utils/api'; // Import the new function

function* registerSaga(action) {
    console.log("registerSaga received action:", action);
    try {
        const responseData = yield call(registerApi, action.payload);
        console.log("registerSaga received response:", responseData);
        yield put(registerSuccess(responseData));
    } catch (error) {
        console.error("registerSaga caught error:", error.message);
        yield put(registerFailure(error.message));
    }
}



function* loginSaga(action) {
  try {
    console.log("loginSaga: Starting login process with payload:", action.payload);

    const loginResponse = yield call(loginUser, {
      username: action.payload.email,
      password: action.payload.password,
    });

    console.log("loginSaga: Response from loginUser:", loginResponse);

    if (loginResponse.success) {
      console.log("loginSaga: Login successful, dispatching LOGIN_SUCCESS.");
      yield put(loginSuccess()); // You might want to pass `loginResponse.data` if user data is needed here
    } else {
      console.log("loginSaga: Login failed, dispatching LOGIN_FAILURE.");
      yield put(loginFailure(loginResponse.message || 'Invalid credentials'));
    }
  } catch (error) {
    console.error("loginSaga: Network error, dispatching LOGIN_FAILURE:", error);
    yield put(loginFailure('Network error'));
  }
}

function* verifyTokenSaga() {
  try {
    console.log("verifyTokenSaga: Starting token verification...");

    const user = yield call(verifyTokenApi);
    console.log("verifyTokenSaga: Response from verifyTokenApi:", user);

    if (user) {
      console.log("verifyTokenSaga: User found, dispatching LOGIN_SUCCESS.");
      yield put(loginSuccess(user));
    } else {
      console.log("verifyTokenSaga: No user found, dispatching VERIFY_TOKEN_FAILURE.");
      yield put({ type: VERIFY_TOKEN_FAILURE });
    }
  } catch (error) {
    console.error("verifyTokenSaga: Error occurred during token verification:", error);
    yield put({ type: VERIFY_TOKEN_FAILURE });
  }
}

function* refreshTokenSaga() {
  try {
    console.log("refreshTokenSaga: Starting refresh token process...");

    const response = yield call(refreshTokenApi);
    console.log("refreshTokenSaga: Response from refreshTokenApi:", response);

    if (response.success) {
      console.log("refreshTokenSaga: Refresh token successful, dispatching LOGIN_SUCCESS.");
      yield put(loginSuccess(response.data));
    } else {
      console.log("refreshTokenSaga: Refresh token failed.");
      // Optionally trigger a logout if refresh fails
    }
  } catch (error) {
    console.error("refreshTokenSaga: Error occurred during token refresh:", error);
    // Optionally trigger a logout on error
  }
}


function* logoutSaga() {
    try {
        const response = yield call(logoutUser);
        if (response.success) {
            // Perform any cleanup, such as removing local storage items
            localStorage.removeItem('token');
            yield put(logoutSuccess());
        } else {
            console.error('Logout failed:', response.message);
            yield put(logoutFailure(response.message));
        }
    } catch (error) {
        console.error('Logout error:', error);
        yield put(logoutFailure(error.message));
    }
}

export function* watchAuthSaga() {
    console.log("watchAuthSaga is running");

    console.log("Setting up takeEvery for REGISTER_REQUEST");
    yield takeEvery(REGISTER_REQUEST, registerSaga);
    yield call(registerSaga, { type: 'REGISTER_REQUEST', payload: { full_name: 'test', email: 'test@test.com', password: 'testpass' } });

    yield takeLatest(LOGIN_REQUEST, loginSaga);
    yield takeLatest(VERIFY_TOKEN_REQUEST, verifyTokenSaga);
    yield takeLatest(REFRESH_TOKEN_REQUEST, refreshTokenSaga);
    yield takeLatest(LOGOUT_REQUEST, logoutSaga)
}