import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
  fetchLearnerProfileDataApi, 
  fetchSearchSuggestionsApi, 
  addUserCourseApi,
  checkLockedStatusApi
} from '../../utils/api'; 
import {
  CHECK_LOCKED_STATUS, // Import the action type for checking locked status
  fetchLearnerProfileDataSuccess,
  fetchLearnerProfileDataFailure,
  fetchSearchSuggestionsSuccess,
  fetchSearchSuggestionsFailure,
  addUserCourseSuccess,
  addUserCourseFailure,
  checkLockedStatus,
  checkLockedStatusSuccess,
  checkLockedStatusFailure,
} from '../actions/learnerProfileActions'; 


import { initiateStartSession } from '../actions/chatActions'
import { reconnectWebSocket } from './chatSagas';

function* fetchLearnerProfileDataSaga() {
  try {
    const data = yield call(fetchLearnerProfileDataApi);
    yield put({ type: 'FETCH_LEARNER_PROFILE_DATA_SUCCESS', payload: data });
  } catch (error) {
    yield put({ type: 'FETCH_LEARNER_PROFILE_DATA_FAILURE', payload: error });
  }
}

function* fetchSearchSuggestionsSaga(action) {
  try {
    const suggestions = yield call(fetchSearchSuggestionsApi, action.payload);
    yield put({ type: 'FETCH_SEARCH_SUGGESTIONS_SUCCESS', payload: suggestions });
  } catch (error) {
    yield put({ type: 'FETCH_SEARCH_SUGGESTIONS_FAILURE', payload: error });
  }
}

function* addUserCourseSaga(action) {
  try {
    const response = yield call(addUserCourseApi, action.payload);
    yield put(addUserCourseSuccess(response));
    
    // Ensure the learning profile data is fetched again to refresh the UI
    yield put({ type: 'FETCH_LEARNER_PROFILE_DATA' });
    
    // Check if WebSocket needs to be reconnected
    if (response.reconnect_websocket) {
      console.log('Reconnecting WebSocket as per the response...');
      yield call(reconnectWebSocket); // Ensure we reconnect and the WebSocket is stable before proceeding

      // Ensure the learning profile data is fetched again after reconnection
      yield put({ type: 'FETCH_LEARNER_PROFILE_DATA' }); // Fetch data again after reconnection
    }
  } catch (error) {
    yield put(addUserCourseFailure(error));
  }
}




function* checkLockedStatusSaga(action) {
  try {
    const { courseId, objectiveId } = action.payload;
    const data = yield call(checkLockedStatusApi, courseId, objectiveId);

    if (data && !data.error) {
      yield put(checkLockedStatusSuccess(data));
      
      if (data) { 
        console.log('data', data)
        yield put(initiateStartSession(data));
      }
    } else {
      yield put(checkLockedStatusFailure('Course or objective is locked or not found'));
    }
  } catch (error) {
    yield put(checkLockedStatusFailure(error.toString()));
  }
}


export default function* learnerProfileSaga() {
  yield all([
    takeLatest('FETCH_LEARNER_PROFILE_DATA', fetchLearnerProfileDataSaga),
    takeLatest('FETCH_SEARCH_SUGGESTIONS', fetchSearchSuggestionsSaga),
    takeLatest('ADD_USER_COURSE', addUserCourseSaga),
    takeEvery(CHECK_LOCKED_STATUS, checkLockedStatusSaga),
  ]);
}

