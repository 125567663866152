import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Image,
  Text,
  Link as ChakraLink,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../assets/images/Logo.png'; // Ensure this path is correct
import { resetRegisterSuccess } from '../redux/actions/authActions'; 

const Registration = () => {
  const dispatch = useDispatch(); 
  const [newUser, setNewUser] = useState({
    full_name: '',
    email: '',
    password: '',
  });
  const navigate = useNavigate();
  const teal = useColorModeValue('teal.500', 'teal.200'); // Adjust teal color based on your logo's color scheme
  const isRegistered = useSelector(state => state.auth.isRegistered);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

// Assuming you're using Redux and have mapDispatchToProps setup
const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting registration:', newUser);
    dispatch({ type: 'REGISTER_REQUEST', payload: newUser });
};

  useEffect(() => {
    if (isRegistered) {
      navigate('/login');
      dispatch(resetRegisterSuccess());
    }
  }, [isRegistered, navigate, dispatch]);


  return (
    <Flex align="center" justify="center" h="100vh" bg="gray.800">
      <Box w="full" maxW="md" p={6} borderRadius="lg" bg="gray.900" boxShadow="lg">
        <Flex justifyContent="center" mb={4}>
          <Image src={logo} alt="Company Logo" objectFit="contain" h="50px" />
        </Flex>
        <form onSubmit={handleSubmit}>
          <FormControl id="full_name" isRequired mb={4}>
            <FormLabel>Full Name <Text as="span" color="white"></Text></FormLabel>
            <Input type="text" name="full_name" value={newUser.full_name} onChange={handleChange} />
          </FormControl>
          <FormControl id="email" isRequired mb={4}>
            <FormLabel>Email <Text as="span" color="white"></Text></FormLabel>
            <Input type="email" name="email" value={newUser.email} onChange={handleChange} />
          </FormControl>
          <FormControl id="password" isRequired mb={6}>
            <FormLabel>Password <Text as="span" color="white"></Text></FormLabel>
            <Input type="password" name="password" value={newUser.password} onChange={handleChange} />
          </FormControl>
          <Button
            type="submit"
            color={teal}
            borderColor={teal}
            variant="outline"
            size="lg"
            fontSize="md"
            w="full"
            _hover={{ bg: 'gray.700' }}
          >
            Register
          </Button>
        </form>
        <Text color="white" mt={6} textAlign="center">
          Already have an account?{' '}
          <ChakraLink as={Link} to="/login" color={teal}>
            Log in here
          </ChakraLink>
        </Text>
      </Box>
    </Flex>
  );
};

export default Registration;
