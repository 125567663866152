import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLearningScheduleRequest, checkLearningSessionLockedStatusRequest } from '../../redux/actions/learningScheduleActions';
import { Box, Text, Flex, Badge, LinkBox, Button, useColorModeValue, VStack, Tooltip, Progress } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { LockIcon } from '@chakra-ui/icons'; // Make sure LockIcon is imported
import { createCheckoutSessionApi } from '../../utils/api';
import { startCalibrationSession } from '../../redux/actions/chatActions';
import { closeAllComponents } from '../../redux/actions/headerActions';

const LearningSchedule = () => {
  const bgColor = useColorModeValue('gray.100', 'gray.900');
  const cardColor = useColorModeValue('gray.100', 'gray.700');
  const overlayColor = useColorModeValue('whiteAlpha.600', 'blackAlpha.600');
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { schedule, loading, error, calibrationRequired, courseId } = useSelector(state => state.learningSchedule);
  
  console.log('schedule', schedule)

  useEffect(() => {
    dispatch(fetchLearningScheduleRequest());
  }, [dispatch]);

  const isObjectiveMastered = (objectives) => {
    return objectives && objectives.some(obj => obj.mastery_level >= 3);
  };

  const handleSessionClick = (sessionId) => {
    dispatch(checkLearningSessionLockedStatusRequest(sessionId));
    dispatch(closeAllComponents())
  };


const handleCalibrateClick = (courseId) => {
  dispatch(startCalibrationSession(courseId));
  dispatch(closeAllComponents())
};

  const handleUpgradeNow = async (courseId) => {
    try {
      const { url } = await createCheckoutSessionApi(courseId);
      if (url) {
        window.location.href = url;
        dispatch(closeAllComponents())
      } else {
        console.error("Failed to get checkout URL");
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error: {error}</Text>;
  }

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const dayWidth = `calc(50vw / ${days.length})`;

  // Assuming 'days' is an array of day names and 'schedule' is your schedule object
  days.forEach(day => {
    const daySchedule = schedule[day.toLowerCase()];
    if (daySchedule) {
      daySchedule.forEach((item, index) => {
        if (!item || !item.session_title) { // Check if the item is null or the session_title is missing
          console.log(`Missing session title at ${day}, index ${index}, session type: ${item?.type}`);
        }
      });
    }
  });

  if (calibrationRequired) {
    return (
      <Box overflowX="auto" width="50vw" mx="auto" p={0} bg={bgColor} borderRadius="lg" boxShadow="lg">
        <VStack spacing={4} align="center" justify="center" height="60vh">
          <Text fontSize="2xl" fontWeight="bold">Calibration Required</Text>
          <Text textAlign="center">
            To generate your personal learning plan, we need to calibrate the course for you.
          </Text>
          <Button
            size="sm" 
            colorScheme="teal" // Use teal for the outline and icon
            variant="outline" // Outline variant for a subtle look
            _hover={{
              borderWidth: "2px", // Thicker border on hover
              padding: "calc(0.75rem - 1px)", // Slightly reduce padding to compensate for thicker border
              fontWeight: "bold" // Bold text on hover
            }}
            onClick={() => handleCalibrateClick(courseId)}
          >
            Calibrate
          </Button>
        </VStack>
      </Box>
    );
  }



  return (
    <Box overflowX="auto" width="50vw" mx="auto" p={0} bg={bgColor} borderRadius="lg" boxShadow="lg">
      <Box bg={bgColor} p={4} textAlign="center" borderRadius="lg">
        {/* Progress Bars and Labels */}
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Text fontSize="lg" fontWeight="bold">Learned:</Text>
            <Progress value={schedule.objectives_learned} max={schedule.objectives_scheduled} />
            <Text fontSize="sm">{schedule.objectives_learned} / {schedule.objectives_scheduled}</Text>
          </Box>
          <Box>
            <Text fontSize="lg" fontWeight="bold">Reinforced:</Text>
            <Progress value={schedule.objectives_reinforced} max={schedule.reinforced_goal} />
            <Text fontSize="sm">{schedule.objectives_reinforced} | Goal: {schedule.reinforced_goal}</Text>
          </Box>
        </Flex>
      </Box>
      <Flex direction="row" spacing={0} width="100%" minWidth="max-content">
        {days.map((day, index) => (
          <VStack key={index} spacing={2} align="stretch" width={dayWidth} minWidth={dayWidth}>
            <Text fontSize="sm" fontWeight="bold" p={2} textAlign="center" bg={bgColor} position="sticky" top="0" zIndex="sticky">
              {day}
            </Text>
            <VStack spacing={2} overflowY="auto" maxH="60vh">
              {schedule[day.toLowerCase()] && schedule[day.toLowerCase()].map(item => (
                <Tooltip key={item.id} label={item.session_title} placement="top" hasArrow>
                  {item.is_locked ? (
                    <Button 
                      size="sm" 
                      colorScheme="teal" // Use teal for the outline and icon
                      variant="outline" // Outline variant for a subtle look
                      _hover={{
                        borderWidth: "2px", // Thicker border on hover
                        padding: "calc(0.75rem - 1px)", // Slightly reduce padding to compensate for thicker border
                        fontWeight: "bold" // Bold text on hover
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUpgradeNow(item.course_id);
                      }}>
                      <LockIcon mr="2" />Upgrade Now
                    </Button>
                  ) : (
                    <LinkBox 
                      size="sm" 
                      colorScheme="teal" // Use teal for the outline and icon
                      variant="outline" // Outline variant for a subtle look
                      _hover={{
                        borderWidth: "2px", // Thicker border on hover
                        padding: "calc(0.75rem - 1px)", // Slightly reduce padding to compensate for thicker border
                        fontWeight: "bold" // Bold text on hover
                      }}
                      cursor="pointer" 
                      onClick={() => handleSessionClick(item.id)}
                      width='95%'
                    >
                      <Badge colorScheme={isObjectiveMastered(item.objectives) ? 'green' : 'blue'} mb={1}>{item.type}</Badge>
                      <Text noOfLines={1} fontSize="xs">{item.session_title}</Text>
                    </LinkBox>
                  )}
                </Tooltip>
              ))}
            </VStack>
          </VStack>
        ))}
      </Flex>
    </Box>
  );
};

export default LearningSchedule;
