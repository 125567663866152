import React, { useEffect, useState, useCallback } from 'react';
import { ChakraProvider, extendTheme, useDisclosure, Box, Flex } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { logoutRequest, verifyTokenRequest } from './redux/actions/authActions';
import store from './redux/store';
import Header from './components/Header/Header';
import Chat from './components/Chat/Chat';
import UserProfile from './components/UserProfile/UserProfile';
import LearningSchedule from './components/LearningSchedule/LearningSchedule';
import Login from './components/Login';
import Registration from './components/Registration';
import Notepad from './components/Notepad/Notepad';
import MarketingPage from './components/MarketingPage'; // Import the new marketing page

// Log the initial store state
console.log("Initial Store State:", store.getState());

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const isNotepadVisible = useSelector((state) => state.notepad.isVisible);
  const { isOpen: isUserProfileOpen, onToggle: onUserProfileToggle } = useDisclosure();
  const { isOpen: isLearningScheduleOpen, onToggle: onLearningScheduleToggle } = useDisclosure();
  const [isDragging, setIsDragging] = useState(false);
  const [notepadWidth, setNotepadWidth] = useState(window.innerWidth * 0.5);

  useEffect(() => {
    dispatch(verifyTokenRequest());
  }, [dispatch]);

  useEffect(() => {
    console.log("Authentication status on load:", auth.isAuthenticated);
    if (auth.isAuthenticated && window.location.pathname.startsWith("/app")) {
      navigate("/app/dashboard", { replace: true });  // Redirect only if on an app route
    }
  }, [auth.isAuthenticated, navigate]);

  const handleLogout = () => {
    dispatch(logoutRequest());
  };

  const startDragging = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const onDrag = useCallback((e) => {
    const newWidth = window.innerWidth - e.clientX;
    const minWidth = window.innerWidth * 0.3;
    const maxWidth = window.innerWidth * 0.6;
    if (newWidth >= minWidth && newWidth <= maxWidth) {
      setNotepadWidth(newWidth);
    }
  }, []);

  const stopDragging = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', onDrag);
      window.addEventListener('mouseup', stopDragging);
    } else {
      window.removeEventListener('mousemove', onDrag);
      window.removeEventListener('mouseup', stopDragging);
    }

    return () => {
      window.removeEventListener('mousemove', onDrag);
      window.removeEventListener('mouseup', stopDragging);
    };
  }, [isDragging, onDrag, stopDragging]);

  const theme = extendTheme({
    config: {
      initialColorMode: 'dark',
      useSystemColorMode: false,
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Registration />} />

        {/* Marketing page: accessible by anyone, including authenticated users */}
        <Route path="/" element={<MarketingPage />} /> 

        {/* Authenticated routes */}
        <Route
          path="/app/*"
          element={
            auth.isAuthenticated ? (
              <Flex direction="column" minH="100vh">
                <Flex flexGrow={1} direction="row">
                  <Box
                    flex={isNotepadVisible ? 1 : 1}
                    p={0}
                    w={isNotepadVisible ? `calc(100% - ${notepadWidth})` : '100%'}
                  >
                    <Header
                      onLogout={handleLogout}
                      onToggleUserProfile={onUserProfileToggle}
                      onToggleLearningSchedule={onLearningScheduleToggle}
                      isNotepadVisible={isNotepadVisible}
                    />
                    <Routes>
                      <Route path="/session/:sessionId" element={<Chat />} />
                      <Route
                        path="/dashboard"
                        element={
                          <>
                            <Chat />
                            {isUserProfileOpen && <UserProfile />}
                            {isLearningScheduleOpen && <LearningSchedule />}
                          </>
                        }
                      />
                    </Routes>
                  </Box>
                  <Box
                    w="1px"
                    cursor="col-resize"
                    bg="gray.900"
                    onMouseDown={startDragging}
                    zIndex={20}
                    border="none"
                    margin={0}
                    padding={0}
                    boxShadow="none"
                  />
                  {isNotepadVisible && (
                    <Box w={`${notepadWidth}px`} h="100vh" bg="gray.900" ml="-1px">
                      <Notepad notepadWidth={notepadWidth} />
                    </Box>
                  )}
                </Flex>
              </Flex>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
      </Routes>
    </ChakraProvider>
  );
}

export default function WrappedApp() {
  return (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  );
}
