import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sendMessage, updateTypingStatus, displayMessage, removeMessageFromQueue } from '../../redux/actions/chatActions';
import { VStack, Flex, Input, IconButton, useColorModeValue, Text, Box, Spinner } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import './Chat.css';
import TypingAnimation from './TypingAnimation';
import { InlineMath, BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';


const Chat = () => {
  const dispatch = useDispatch();
  const { sessionId } = useParams();
  const { messages, isTyping, messageQueue, userLearningSessionID, waitingForServerResponse } = useSelector(state => state.chat);
  const [userMessage, setUserMessage] = useState('');
  const chatHistoryRef = useRef(null);
  const inputBgColor = useColorModeValue('white', 'gray.600');

  useEffect(() => {
    if (sessionId) {
      // Constructing the message object correctly according to your action creator's expected input
      const messageObject = {
        message: `start session ${sessionId}`,
        author: 'self',
        userLearningSessionID: '',
      };
      dispatch(sendMessage(messageObject));
    }
  }, [dispatch, sessionId, userLearningSessionID]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (chatHistoryRef.current) {
        chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
      }
    };
    scrollToBottom();
  }, [messages, isTyping]);

const handleSendMessage = (e) => {
  e.preventDefault();
  const messageObject = {
    type: 'message',
    message: userMessage,
    author: 'self',
    userLearningSessionID: userLearningSessionID,
  };
  if (userMessage.trim()) {
    // Directly pass parameters to match the sendMessage action creator's signature.
    dispatch(sendMessage(messageObject));
    setUserMessage('');
  }
};

if (isTyping && messageQueue.length > 0) {
  console.log('isTyping:', isTyping, 'Message in queue:', messageQueue[0].message);
}

const handleTypingDone = () => {
  dispatch(updateTypingStatus(false)); // First, update the typing status to false
  const nextMessageObject = messageQueue[0]; // Get the next message object from the queue
  dispatch(displayMessage(nextMessageObject)); // Then, dispatch the action to display the message
  dispatch(removeMessageFromQueue()); // Remove the message from the queue
};

const renderMessageContent = (message) => {
  const imgRegex = /\{\{img:([^}]+)\}\}/g;

  // Process images separately
  let contentWithImages = [];
  let lastIndex = 0;
  message.replace(imgRegex, (match, imageName, index) => {
    if (index > lastIndex) {
      contentWithImages.push(message.substring(lastIndex, index));
    }
    contentWithImages.push(`![image](${process.env.REACT_APP_API_BASE_URL}/images/${imageName}.png)`);;
    lastIndex = index + match.length;
    return "";
  });
  if (lastIndex < message.length) {
    contentWithImages.push(message.substring(lastIndex));
  }

  const processedMessage = contentWithImages.join('');

  return (
    <Box p={2}>
      <ReactMarkdown
        remarkPlugins={[remarkMath]}
        rehypePlugins={[rehypeKatex]}
        components={{
          p: ({ node, ...props }) => <Text {...props} as="p" mb={2} whiteSpace="pre-wrap" />,
          math: ({ value }) => <BlockMath>{value}</BlockMath>,
          inlineMath: ({ value }) => <InlineMath>{value}</InlineMath>,
        }}
        breaks // Enable line breaks
      >
        {processedMessage}
      </ReactMarkdown>
    </Box>
  );
};
 
console.log('messageQueue', messageQueue);
if (messageQueue.length > 0) {
  console.log('Current message:', messageQueue[0].message);
}

return (
  <Flex direction="column" h={`calc(100vh - 60px)`}>
    <VStack spacing="1" overflowY="auto" p="4" flex="1" ref={chatHistoryRef}>
      {messages.map((messageObject, index) => (
          <Flex key={index} direction="column" alignSelf={messageObject.author === 'Self' ? 'flex-end' : 'flex-start'} mb={2}>
              <Text fontSize="xs" color="gray.500">
                  {messageObject.author === 'self' ? 'You' : 'Tutor'}
              </Text>
              {messageObject.type === 'error' ? (
                  <Box bg="transparent" border="2px solid teal" p={2} borderRadius="lg">
                      {renderMessageContent(messageObject.message)}
                  </Box>
              ) : (
                  <Box p={2} borderRadius="lg">
                      {renderMessageContent(messageObject.message)}
                  </Box>
              )}
          </Flex>
      ))}
      {waitingForServerResponse && (
        <Flex direction="column" alignSelf="flex-start" mb={2}>
          <Text fontSize="xs" color="gray.500">Tutor</Text>
          <Flex align="center" justify="center" p={2}>
            <Spinner size="sm" /> {/* Pulsating indicator */}
          </Flex>
        </Flex>
      )}
      {isTyping &&   messageQueue.length > 0 && (
        <Flex direction="column" alignSelf="flex-start" mb={2}>
          <Text fontSize="xs" color="gray.500">Tutor</Text>
          <TypingAnimation 
            message={messageQueue[0].message} 
            speed={1} 
            onTypingDone={handleTypingDone}
          />
        </Flex>
      )}
    </VStack>
    <Box p="4" bg={inputBgColor} borderTopWidth="1px" position="sticky" bottom="0" zIndex="sticky">
      <Flex
        as="form"
        onSubmit={handleSendMessage}
        align="center"
        borderRadius="full"
        border="none"
        _focus={{ boxShadow: 'none' }}
      >
        <Input
          flex="1"
          variant="filled"
          placeholder="Type a message..."
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          bg={inputBgColor}
          borderRadius="full"
          border="none"
          _focus={{ boxShadow: 'none' }}
        />
        <IconButton
          icon={<ArrowForwardIcon />}
          type="submit"
          colorScheme="blue"
          aria-label="Send message"
          borderRadius="full"
          ml={2}
          disabled={waitingForServerResponse}
        />
      </Flex>
    </Box>
  </Flex>
);


};

export default Chat;


