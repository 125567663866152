import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Image,
  Text,
  Link as ChakraLink,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { loginRequest } from '../redux/actions/authActions';
import logo from '../assets/images/Logo.png'; // Ensure this path is correct

const Login = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/app/dashboard";
  const teal = useColorModeValue('teal.500', 'teal.200'); // Get the teal color from the theme

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate(from, { replace: true });
    }
  }, [auth.isAuthenticated, navigate, from]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loginRequest(credentials));
  };

  return (
    <Flex align="center" justify="center" h="100vh" bg="gray.800">
      <Box w="full" maxW="md" p={6} borderRadius="lg" bg="gray.900" boxShadow="lg">
        <Flex justifyContent="center" mb={4}>
          <Image src={logo} alt="Company Logo" objectFit="contain" h="50px" />
        </Flex>
        <form onSubmit={handleSubmit}>
          <FormControl id="email" mb={4}>
            <FormLabel>Email <Text as="span" color="white"></Text></FormLabel>
            <Input type="email" name="email" value={credentials.email} onChange={handleChange} />
          </FormControl>
          <FormControl id="password" mb={6}>
            <FormLabel>Password <Text as="span" color="white"></Text></FormLabel>
            <Input type="password" name="password" value={credentials.password} onChange={handleChange} />
          </FormControl>
          <Button
            type="submit"
            color={teal}
            borderColor={teal}
            variant="outline"
            size="lg"
            fontSize="md"
            w="full"
            _hover={{ bg: 'gray.700' }}
          >
            Login
          </Button>
        </form>
        <Text color="white" mt={6} textAlign="center">
          Don't have an account?{' '}
          <ChakraLink as={Link} to="/register" color={teal}>
            Register here
          </ChakraLink>
        </Text>
      </Box>
    </Flex>
  );
};

export default Login;
