import { Box, Button, Heading, Text, Flex, Image, keyframes } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/Logo.png';  // Restored logo import
import heroImage from '../assets/images/landing_page_graphic.png';  // Placeholder for the new image

// Keyframes for continuous sliding
const slide = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }  // Slide full length of testimonials
`;

const testimonials = [
  { text: "“The AI tutor helped me raise my score by 150 points in just a month!”", author: "Sarah P." },
  { text: "“The personalized lessons made a huge difference—I felt totally prepared for the SAT.”", author: "John K." },
  { text: "“My SAT score improved dramatically thanks to the custom study sessions.”", author: "Emily S." },
  { text: "“I was able to focus on my weak areas, and it boosted my overall score.”", author: "David L." },
  { text: "“The tailored lessons were perfect for my learning style.”", author: "James B." },
  { text: "“This AI tutoring platform is the best SAT prep I’ve used.”", author: "Katie M." }
];

const MarketingPage = () => {
  const navigate = useNavigate();

  const handleSignUp = () => {
    navigate('/register');  // Redirects to the app registration
  };

  // Continuous sliding animation with smooth looping
  const slideAnimation = `${slide} 30s linear infinite`;  // Slower for smoother sliding

  return (
    <>
      {/* Header Section with Logo */}
      <Flex
        as="header"
        justify="space-between"
        align="center"
        w="100%"
        p={2}  // Keep header padding minimal
        bg="gray.900"
        position="fixed"
        top="0"
        zIndex="10"
      >
        <Image
          src={logo}
          alt="Company logo"
          htmlWidth="150px"  // Maintain logo size
          objectFit="contain"
        />
      </Flex>

      {/* Hero Section with Image and Text */}
      <Flex
        direction={{ base: 'column', lg: 'row' }}  // Stack on small screens, side-by-side on larger screens
        justify="center"
        align="center"
        minH="100vh"
        bg="gray.800"
        color="white"
        textAlign="center"
        p={4}  // Reduced padding around the hero section
        pt="90px"  // Adjust padding-top to account for the fixed header
      >
        {/* Left section: Headline, CTA */}
        <Flex
          direction="column"
          justify="center"
          align="center"
          maxW="600px"
          w="100%"
          p={2}  // Reduced padding around the text content
        >
          {/* Headline and Subheadline */}
          <Heading as="h1" mb={2} fontSize={{ base: '4xl', md: '6xl' }} color="teal.300">
            Ace the SAT with AI
          </Heading>
          <Heading as="h2" mb={3} fontSize={{ base: 'xl', md: '3xl' }} color="whiteAlpha.900" fontWeight="bold">
            50+ point boost or your moneyback*
          </Heading>

          {/* Call to Action */}
          <Button
            color="teal.300" 
            variant="outline" 
            size="lg"  // Keep button size the same
            onClick={handleSignUp}
            _hover={{ bg: "teal.400", boxShadow: "lg" }}
            fontSize="lg"
            mb={4}  // Reduced bottom margin for CTA
          >
            Get full practice test + 10 hours tutoring FREE
          </Button>
        </Flex>

        {/* Right section: Hero Image */}
        <Flex
          justify="center"
          align="center"
          maxW="600px"
          w="100%"
          p={2}  // Reduced padding around the image
          mt={{ base: 10, lg: 0 }}  // Add margin on top for small screens
        >
          <Image
            src={heroImage}  // Replace with your actual image path
            alt="Hero Image"
            maxW="100%"
            borderRadius="lg"
            boxShadow="xl"
          />
        </Flex>
      </Flex>

      {/* Continuous Looping Testimonials */}
      <Flex
        w="100%" 
        bg="gray.800"
        color="white"
        py={4}  // Reduced padding for the testimonial section
        overflow="hidden"
        justify="center"
      >
        <Box
          display="flex"
          animation={slideAnimation}  // Apply continuous sliding animation
          width="200%"  // Wide enough for two sets of testimonials to scroll smoothly
        >
          {/* Loop through the testimonials twice to achieve infinite scrolling */}
          {testimonials.concat(testimonials).map((testimonial, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="gray.700"
              borderRadius="md"
              boxShadow="lg"
              p={3}  // Minimal padding around testimonials
              mx={2}  // Reduced margin between testimonials
              minW="280px"  // Maintain a fixed width for each testimonial
            >
              <Text fontSize="md" color="whiteAlpha.900" textAlign="center">
                {testimonial.text}
              </Text>
              <Text fontSize="sm" color="whiteAlpha.700" mt={2} textAlign="center">
                — {testimonial.author}
              </Text>
            </Box>
          ))}
        </Box>
      </Flex>
    </>
  );
};

export default MarketingPage;