import React, { useEffect, useRef } from 'react';
import { BlockNoteView, useBlockNote, FormattingToolbarPositioner, SlashMenuPositioner } from "@blocknote/react";
import "@blocknote/react/style.css";
import { Box, useColorModeValue, IconButton, Flex } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { toggleNotepad } from '../../redux/actions/notepadActions';
import { useDispatch } from 'react-redux';

function Notepad({ onToggleNotepad }) {
  const bgColor = useColorModeValue('gray.100', 'gray.900');
  const dispatch = useDispatch();
  const editor = useBlockNote({
    defaultStyles: false,
  });

  // Ref for the content container
  const contentContainerRef = useRef();

  const iconColor = useColorModeValue('teal.500', 'teal.200');
  const handleToggleNotepad = () => {
    dispatch(toggleNotepad());
  };

  useEffect(() => {
    const blockNoteRoot = document.querySelector('.bn-editor');
    if (blockNoteRoot) {
      blockNoteRoot.style.setProperty('--bn-colors-editor-background', bgColor);
      blockNoteRoot.style.setProperty('--bn-colors-editor-text', 'white');
      blockNoteRoot.style.setProperty('--bn-colors-menu-background', bgColor);
      blockNoteRoot.style.paddingInline = '20px';
    }
  }, [bgColor]);

  return (
    <Box
      h="100vh"
      p={0}
      bg={bgColor}
      color="white"
      borderLeft="1px solid"
      borderColor="gray.600"
      zIndex="overlay"
    >
      {/* Sticky header with the Hamburger icon */}
      <Flex
        justify="flex-end"
        align="center"
        height="60px"
        bg={bgColor} // Match the background color of the notepad for consistency
        zIndex={10}
        position="sticky"
        top="0"
      >
        <IconButton
          icon={<ChevronRightIcon />}
          aria-label="Toggle Notepad"
          variant="ghost"
          color={iconColor}
          fontSize="35px"
          opacity="0.5"
          onClick={handleToggleNotepad}
        />
      </Flex>
      
      {/* Scrollable notepad content area */}
      <Box
        ref={contentContainerRef}
        h={`calc(100vh - 60px)`}// Adjust the height to take into account the header height
        overflowY="auto" // Apply scroll to the content container only
        overscrollBehavior="none"
      >
        <BlockNoteView editor={editor} theme="dark">
          <FormattingToolbarPositioner editor={editor} />
          <SlashMenuPositioner editor={editor} />
          {/* Add any other positioners if needed */}
        </BlockNoteView>
      </Box>
    </Box>
  );
}

export default Notepad;
